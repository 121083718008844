import * as React from 'react';
import {Link} from "gatsby";

// @ts-ignore
import CaptisLogo from '../../assets/images/lambda-captis.svg';

// @ts-ignore
import LambdaLogo from '../../assets/images/lambda9-standalone.svg';

export default function Header() {
    return (
        <div className="row pt-5 pb-4 ps-0 pe-0">
            <div className="col-4">
                <a className="d-block" href="/" style={{maxWidth: "120px"}}>
                    <img src={CaptisLogo}/>
                </a>
            </div>
            <div className="col-6 d-flex justify-content-end">
                <nav className="navbar navbar-expand-md navbar-light justify-content-end">
                    <button className="navbar-toggler me-4 align-self-start" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link className="nav-link" to="/">Start</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/news">News</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/preise">Preise</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
            <div className="col-2 d-flex flex-column justify-content-md-center justify-content-start">
                <a id="anmelden" href="/login" style={{minWidth: "70px", maxWidth: "90px", minHeight: "44px", borderRadius: "20px"}}
                   className="btn btn-light d-flex align-self-end">
                <span className="align-self-center text-center">
                    Login
                </span>
                    <i className="align-self-center ms-1 fas fa-sign-in-alt"></i>
                </a>
            </div>
        </div>
    );
}