import * as React from 'react';

// @ts-ignore
import CaptisLogo from '../../assets/images/lambda-captis.svg';

// @ts-ignore
import LambdaLogo from '../../assets/images/lambda9-standalone.svg';
import {Link} from "gatsby";

// Preisseite dazubauen

export default function Footer() {
    return (
        <footer>
            <div className="row p-4 pt-5">
                <div className="col-4 d-flex justify-content-center">
                    <img src={CaptisLogo} style={{width: "70px", height: "45px"}}/>
                </div>
                <div className="col-4 border-left" style={{borderLeft: "1px dashed #bbb"}}>
                    <h6 className="fw-bold ms-2">Mehr Captis</h6>
                    <ul className="ms-2 ps-0" style={{listStyle: "none"}}>
                        <li className="mt-2">
                            <a className="text-underline" href="/registrierung">
                                Kostenlos probieren
                            </a>
                        </li>
                        <li className="mt-2">
                            <Link className="text-underline" to="/news">
                                News
                            </Link>
                        </li>
                        <li className="mt-2">
                            <Link className="text-underline" to="/preise">
                                Preise
                            </Link>
                        </li>
                        <li className="mt-2">
                            <a className="text-underline" target="_blank" href="/agbs">
                                AGBs
                            </a>
                        </li>
                        <li className="mt-2">
                            <a className="text-underline"  target="_blank" href="/datenschutz">
                                Datenschutz
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-4" style={{borderLeft: "1px dashed #bbb"}}>
                    <h6 className="ms-2 fw-bold">Mehr lambda9</h6>
                    <ul className="ms-2 ps-0" style={{listStyle: "none"}}>
                        <li className="mt-2">
                            <a target="_blank" href="https://signieren.online" className="text-underline">
                                sign
                            </a>
                        </li>
                        <li className="mt-2">
                            <a target="_blank" href="https://lambda9.de/produkte/porto/" className="text-underline">
                                lambda porto
                            </a>
                        </li>
                        <li className="mt-2">
                            <a target="_blank" href="https://lambda9.de/produkte/perso/" className="text-underline">
                                lambda perso
                            </a>
                        </li>
                        <li className="mt-2">
                            <a target="_blank" href="https://lambda9.de/produkte/bills/" className="text-underline">
                                lambda bills
                            </a>
                        </li>
                        <li className="mt-2">
                            <a target="_blank" href="https://lambda9.de/software/kompetenzen/" className="text-underline">
                                lambda dev
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 d-flex justify-content-center p-4">
                    <div className="align-self-center">
                        <a className="text-underline" href="https://lambda9.de/recht/impressum/"
                           target="_blank">Impressum</a>
                        &nbsp;&nbsp;·&nbsp;&nbsp;
                        powered by <a href="https://lambda9.de"><img src={LambdaLogo} style={{width: "1.2rem", height: "1.2rem"}}/> lambda9
                        GmbH</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}